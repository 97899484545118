import styled from "styled-components";

export const Pagination = styled.div`
  text-align: center;
  margin: 60px 0px 40px;

  .app__pagination {
    width: 100%;
    border-radius: 6px;
    background: white;
    box-shadow: 0 12px 20px 2px rgb(0 0 0 / 17%);
    padding: 25px 25px 20px;
    margin-bottom: 30px;
  }
  & .pagination {
    display: inline-flex;
    margin-bottom: 0;
  }
  & .page-item {
    & .page-link {
      font-size: 16px;
      color: #212529;
      font-weight: 600;
      border: 1px solid #c6cbd3;
      margin-right: -1px;
      padding: 12px 24px;
      &:focus {
        box-shadow: none;
        background-color: #6c5dd3;
        border-color: #6c5dd3;
        color: #fff;
      }
    }
    &:first-child {
      & .page-link {
        border-radius: 12px 0px 0px 12px;
      }
    }
    &:last-child {
      & .page-link {
        border-radius: 0px 12px 12px 0px;
      }
    }
    &.active {
      & .page-link {
        background-color: #6c5dd3;
        border-color: #6c5dd3;
        color: #fff;
      }
    }
  }
`;

export const RefInviteBtn = styled.button`
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  min-height: 40px;
  min-width: 140px;
  padding: 8px 12px;
  position: relative;
  background: #6c5dd3;
  -webkit-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
`;
