import { UserType } from "@remotebase/constants";
import { FC } from "react";
import * as text from "../../utils";
import { imageUrls } from "../../utils";
import * as Styled from "./styles";

interface Props {
  authComponent: JSX.Element;
  userType?: UserType;
}

export const AuthenticationHeader: FC<Props> = (props) => {
  const { authComponent, userType } = props;

  const getAuthHeader = (): string => {
    switch (userType) {
      case UserType.Talent:
        return text.talentHeaderText;
      case UserType.Client:
        return text.clientHeaderText;
      case UserType.Recruiter:
        return text.recruiterHeaderText;
      default:
        return text.adminHeaderText;
    }
  };
  return (
    <Styled.LoginWrapper>
      <Styled.LoginContent>
        <Styled.LoginCanvas>{authComponent}</Styled.LoginCanvas>
      </Styled.LoginContent>
      <Styled.LoginBg>
        <Styled.LoginAccessBox>
          <Styled.LoginAccessContent>
            <Styled.Tagline>{getAuthHeader()}</Styled.Tagline>
            <Styled.AccessOne>
              <Styled.AccessOneImg src={imageUrls.womenImg} alt="Img" />
            </Styled.AccessOne>
          </Styled.LoginAccessContent>
          <Styled.TaglineBoxOne>
            <Styled.TaglineBoxOneImg src={imageUrls.hundredImg} alt="Img" />
          </Styled.TaglineBoxOne>
          <Styled.TaglineBoxTwo>
            <Styled.TaglineBoxTwoImg src={imageUrls.handImg} alt="Img" />
          </Styled.TaglineBoxTwo>
        </Styled.LoginAccessBox>
      </Styled.LoginBg>
    </Styled.LoginWrapper>
  );
};

export default AuthenticationHeader;
