import { FC } from "react";
import {
  refFacebookIconTestId,
  refLinkedInIconTestId,
  refLinkSocialsLabel,
  refQuote,
  refTwitterIconTestId,
  SocialIcons,
  socialIcons,
} from "utils";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import * as Styled from "./styles";

interface Props {
  shareUrl: string;
}

export const RefSocialShareBtns: FC<Props> = ({ shareUrl }) => {
  return (
    <Styled.LinkSocialsBox>
      <Styled.LinkSocialsText>{refLinkSocialsLabel}</Styled.LinkSocialsText>
      <Styled.SocialIcons>
        <TwitterShareButton data-testid={refTwitterIconTestId} title={refQuote} url={shareUrl}>
          {socialIcons[SocialIcons.Twitter]}
        </TwitterShareButton>
        <FacebookShareButton data-testid={refFacebookIconTestId} quote={refQuote} url={shareUrl}>
          {socialIcons[SocialIcons.Facebook]}
        </FacebookShareButton>
        <LinkedinShareButton data-testid={refLinkedInIconTestId} summary={refQuote} url={shareUrl}>
          {socialIcons[SocialIcons.LinkedIn]}
        </LinkedinShareButton>
      </Styled.SocialIcons>
    </Styled.LinkSocialsBox>
  );
};

export default RefSocialShareBtns;
