import styled from "styled-components";

export const Pagination = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;

  .app__pagination {
    width: 100%;
    border-radius: 6px;
    background: white;
    box-shadow: 0 12px 20px 2px rgb(0 0 0 / 17%);
    padding: 25px 25px 20px;
    margin-bottom: 30px;
  }
  & .pagination {
    display: inline-flex;
    margin-bottom: 0;
    margin: 0 5px;
  }
  & .page-item {
    & span {
      font-size: 16px;
      color: #212529;
      font-weight: 600;
      border: 1px solid #c6cbd3;
      margin-right: -1px;
      padding: 12px 24px;
      &:focus {
        box-shadow: none;
        background-color: #6c5dd3;
        border-color: #6c5dd3;
        color: #fff;
      }
    }
    & a {
      font-size: 16px;
      color: #212529;
      font-weight: 600;
      border: 1px solid #c6cbd3;
      margin-right: -1px;
      padding: 12px 24px;
      &:hover {
        background-color: #6c5dd3;
        border-color: #6c5dd3;
        color: #fff;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &.disabled {
      & span {
        color: #babbbc;
      }
    }
    &:first-child {
      & .page-link {
        border-radius: 12px 0px 0px 12px;
      }
    }
    &:last-child {
      & .page-link {
        border-radius: 0px 12px 12px 0px;
      }
    }
    &.active {
      & .page-link {
        background-color: #6c5dd3;
        border-color: #6c5dd3;
        color: #fff;
      }
    }
  }
  @media (max-width: 575px) {
    .paginationNos {
      display: none;
    }
  }
`;

export const NextBtn = styled.button`
  opacity: 1;
  position: relative;
  background-color: #6c5dd3;
  color: #ffffff;
  font-size: 18x;
  background: #ff2c2c;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  transition: all 0.2s;
  color: white !important;
  font-weight: 700;
  border: none;
  min-height: 48px;
  min-width: 10px;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }

  &:disabled {
    background: #f2f2f2;
    color: #8d8d8d !important;
    pointer-events: none;
  }
  &:disabled svg * {
    fill: #8d8d8d;
  }
  svg {
    margin-left: 19px;
  }
  img {
    margin-left: 19px;
  }
  background: #4c40f7;
  color: #ffffff;
  .disabled {
    background: #4c40f7;
    color: #ffffff !important;
  }
  @media (max-width: 767px) {
    min-height: 40px;
    font-size: 14px;
    padding: 0 15px;
    svg {
      height: 12px;
    }
  }
`;
