import { FC } from "react";

import { ReferralStages } from "@remotebase/constants";

import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { InfoIcon } from "../assets";
import * as Styled from "./styles";

interface Props {
  color: string;
  number: number;
  text: string;
  tooltip: string;
  onClick?: (e: ReferralStages | unknown) => void;
  selectedFilter?: string;
  setSelectedFilter?: (filter: string) => void;
}

export const TrackerBoxWithTooltip: FC<Props> = ({
  color,
  number,
  text,
  tooltip,
  onClick,
  selectedFilter,
  setSelectedFilter,
}) => {
  const tooltipElement = () => <Tooltip id="referral-tooltip">{tooltip}</Tooltip>;

  const onFilterChange = (filterValue: string): void => {
    if (onClick && setSelectedFilter) {
      setSelectedFilter(filterValue);
      onClick(filterValue);
    }
  };

  return (
    <Styled.OverlayWrapper
      className={`${text === selectedFilter ? "active" : ""}`}
      onClick={(): void => onFilterChange(text)}
    >
      <OverlayTrigger placement="top" overlay={tooltipElement()}>
        {({ ref, ...triggerHandler }): JSX.Element => (
          <Styled.TrackRefBox active="active" color={color}>
            <Row>
              <Col>
                <Styled.Digit>{number}</Styled.Digit>
              </Col>
              <Col>
                <Styled.InfoIcon {...triggerHandler}>
                  <InfoIcon ref={ref} />
                </Styled.InfoIcon>
              </Col>
            </Row>
            <Styled.TrackRefBoxText>{text}</Styled.TrackRefBoxText>
          </Styled.TrackRefBox>
        )}
      </OverlayTrigger>
    </Styled.OverlayWrapper>
  );
};

export default TrackerBoxWithTooltip;
