import styled from "styled-components";

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DashboardInner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

export const HeadingMain = styled.h1`
  font-size: 40px;
  color: #11142d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
`;

export const HeadingSmall = styled.h5`
  font-size: 18px;
  color: #11142d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;

export const WorkDiv = styled.div`
  position: relative;
  border-radius: 24px;
  margin-bottom: 80px;
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: #cfc8ff;
    border-radius: 24px;
  }
  &:before {
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: -8px;
  }
  &:after {
    top: 16px;
    left: 20px;
    right: 20px;
    bottom: -16px;
  }

  &.scroll {
    color: #fff;
  }

  @media only screen and (max-width: 1279px) {
    .scroll {
      display: none;
    }
  }
`;

export const WorkCanvas = styled.div`
  position: relative;
  background: #6c5dd3;
  border-radius: 24px;
  padding: 112px 24px 60px 90px;
  z-index: 1;

  @media only screen and (max-width: 1439px) {
    padding-left: 60px;
  }

  @media (max-width: 767px) {
    padding: 56px 24px 475px;
  }
`;

export const WorkCircles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 64.4%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
  &:before {
    width: 880px;
    height: 880px;
    right: calc(50% - 750px);
  }
  &:after {
    width: 1570px;
    height: 1570px;
    right: calc(50% - 1190px);
  }

  @media only screen and (max-width: 1279px) {
    &:before {
      right: calc(50% - 610px);
      width: 730px;
      height: 730px;
    }
    &:after {
      right: calc(50% - 950px);
      width: 1290px;
      height: 1290px;
    }
  }

  @media (max-width: 767px) {
    &:before,
    &:after {
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:before {
      top: 104px;
      width: 368px;
      height: 368px;
    }
    &:after {
      top: -15px;
      right: auto;
      width: 600px;
      height: 600px;
    }
  }
`;

export const WorkTextWrapper = styled.div`
  position: relative;
  z-index: 10000;
  max-width: 570px;
  color: #fff;

  @media only screen and (max-width: 1279px) {
    max-width: 420px;
  }
`;

export const Tagline = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding-left: 68px;
  font-size: 32px;
  color: #ffbdbd;
  line-height: 1.4;
  font-weight: 600;
  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 52px;
    height: 2px;
    background: #ffbdbd;

    @media only screen and (max-width: 1279px) {
      color: #fff;

      &:before {
        background: #fff;
      }
    }
  }
`;

export const H2 = styled.h2`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.3;

  @media only screen and (max-width: 1279px) {
    margin-bottom: 32px;
    font-size: 32px;
  }
`;

export const WorkText = styled.div`
  max-width: 386px;
  margin-bottom: 0px;

  @media only screen and (max-width: 1279px) {
    max-width: 270px;
    margin: 0;
  }
`;

export const PerksDiv = styled.div`
  margin-top: 60px;
`;

export const PerksText = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 26px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

export const WorkImgBox = styled.div`
  position: absolute;
  top: 22px;
  left: calc(50% - 48%);
  width: 172px;
  height: 107px;
  font-size: 0;

  @media only screen and (max-width: 1279px) {
    top: 34px;
    left: 10px;
    width: 140px;
  }

  @media (max-width: 767px) {
    top: 210px;
    left: auto;
    right: 35px;
  }
`;

export const WorkImg = styled.img`
  width: 100%;
`;
