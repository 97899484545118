import styled from "styled-components";

export const EmailListText = styled.div`
  font-size: 18px;
  color: #808191;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 16px;
`;

export const PurpleBtn = styled.button`
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  min-height: 52px;
  min-width: 170px;
  padding: 8px 20px;
  position: relative;
  background: #6c5dd3;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }
`;

export const CopyLinkBox = styled.div`
  display: flex;
  input {
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: "Poppins", sans-serif !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
    height: 52px !important;
    padding: 5px 20px !important;
    margin-bottom: 20px !important;
    width: 100% !important;
  }
  button {
    margin-bottom: 20px;
    margin-left: 22px;
    @media screen and (max-width: 575px) {
      margin: 0;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

// PERSONAL REFERRAL LINK AREA
export const PersonalLinkBox = styled.div`
  width: 100%;
  max-width: 550px;
  margin-bottom: 38px;
`;
