import { StyledProps } from "utils";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarProfile = styled.div`
  position: relative;

  @media (max-width: 1199px) {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const ProfileBox = styled(Link)`
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  height: 62px;
  border-radius: 12px;
  color: #11142d;
  cursor: pointer;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;

  &:hover {
    background: #3f8cff;
    color: #ffffff;
    text-decoration: none;
  }

  @media (max-width: 1023px) {
    padding-left: 8px;
  }
`;

export const AvatarBox = styled.div`
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  background-color: #f2ecec;
`;

export const ProfileInfo = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 64px);
  flex: 0 0 calc(100% - 64px);
  width: calc(100% - 64px);
  padding: 0 5px 0 16px;
`;

export const Name = styled.div`
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
  color: ${(props): StyledProps => props.theme.primaryFontColor};
  ${ProfileBox}:hover & {
    color: ${(props): StyledProps => props.theme.background};
  }
`;

export const Title = styled.div`
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: ${(props): StyledProps => props.theme.secondaryFontColor};
  ${ProfileBox}:hover & {
    color: ${(props): StyledProps => props.theme.background};
  }
`;

export const ArrowIconBox = styled.div`
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0;

  font-size: 16px;
  fill: ${(props): StyledProps => props.theme.primaryFontColor};
`;
