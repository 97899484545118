import styled from "styled-components";

export const RefTableWrapper = styled.div`
  margin-bottom: 20px;

  .table-responsive {
    min-height: 482px;
    position: relative;
    padding-bottom: 138px;
  }
`;

export const NoSignupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 20px;
`;
