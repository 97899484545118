import styled from "styled-components";

export const WorkFigure = styled.div`
  width: 100%;
  pointer-events: none;

  &:first-child {
    position: relative;
    top: -70px;
  }
  &:nth-child(1) {
    top: 50px;
    right: -29%;
    z-index: 2;
    width: 57%;
  }
  &:nth-child(2) {
    top: 13%;
    left: 30.4%;
    z-index: 2;
    width: 53%;
  }
  &:nth-child(3) {
    top: 14%;
    left: 31%;
    z-index: 2;
    width: 55%;
  }
  &:nth-child(4) {
    top: 22%;
    left: 35.4%;
    z-index: 2;
    width: 48%;
  }
  &:nth-child(5) {
    top: 15%;
    left: 29.8%;
    z-index: 3;
    width: 54%;
  }
  &:nth-child(6) {
    top: 19%;
    left: 31.8%;
    z-index: 3;
    width: 54%;
  }
  &:nth-child(7) {
    top: 18%;
    left: 31.3%;
    z-index: 4;
    width: 54%;
  }
  &:not(:first-child) {
    position: absolute;
  }

  @media (max-width: 767px) {
    &:first-child {
      top: 0;
      width: 96%;
      right: -3%;
    }
    &:nth-child(2) {
      width: 90%;
      top: -4%;
      left: -10%;
    }
    &:nth-child(3) {
      width: 87%;
      top: -4.2%;
      left: 20%;
    }
    &:nth-child(4) {
      width: 82%;
      top: 8%;
      left: 12.6%;
    }
    &:nth-child(5) {
      width: 86%;
      top: 2%;
      left: 4%;
    }
    &:nth-child(6) {
      width: 86%;
      top: 0%;
      left: 14.5%;
    }
    &:nth-child(7) {
      width: 86%;
      top: 6%;
      left: 9.5%;
    }
  }
`;

export const WorkImg = styled.img`
  width: 100%;
`;
