import React from "react";
import { AuthRoutes } from "utils";
import * as Styled from "./styles";

export const SidebarLogoutBtn: React.FC = () => {
  return (
    <Styled.LogoutBtnWrapper>
      <Styled.LogoutBtnBox to={AuthRoutes.Logout}>
        <Styled.LogoutBtnInner>
          <Styled.LogoutBtnText>Logout</Styled.LogoutBtnText>
        </Styled.LogoutBtnInner>
      </Styled.LogoutBtnBox>
    </Styled.LogoutBtnWrapper>
  );
};

export default SidebarLogoutBtn;
