import { ShouldRender } from "@remotebase/components";
import { ErrorProps } from "@remotebase/constants";
import { BtnLoader } from "components";
import { useReInviteEmails } from "hooks";
import { FC, useEffect, Fragment } from "react";
import withError from "state/error/withErrorHoc";
import {
  refInviteEmailTitleError,
  refInviteEmailTitleSuccess,
  reInviteEmailSuccessMessage,
  ResentInviteBtn,
} from "utils";
import * as Styled from "./styles";

interface Props {
  email: string;
  onInvitation: (email: string) => void;
}

export const ReInviteBtn: FC<Props & ErrorProps> = ({ email, showError, onInvitation }) => {
  const { refProfile, isLoading, error, success, data } = useReInviteEmails();
  const handleOnReInvite = (): void => refProfile({ variables: { email } });

  useEffect(() => {
    if (!isLoading && data) {
      console.log("Response: ", data, success, isLoading);
      if (success) {
        showError({
          title: refInviteEmailTitleSuccess,
          message: data?.reInviteEmail?.message || reInviteEmailSuccessMessage,
        });
        onInvitation(email);
      } else if (error) {
        showError({ title: refInviteEmailTitleError, message: error?.message });
      }
    }
  }, [isLoading]);

  return (
    <Styled.RefInviteBtn onClick={handleOnReInvite} disabled={isLoading}>
      <ShouldRender if={isLoading}>
        <BtnLoader />
      </ShouldRender>
      <ShouldRender if={!isLoading}>
        <Fragment>{ResentInviteBtn}</Fragment>
      </ShouldRender>
    </Styled.RefInviteBtn>
  );
};

export default withError(ReInviteBtn);
