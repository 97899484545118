import styled from "styled-components";

export const NoSignupsImgBox = styled.div`
  pointer-events: none;
`;

export const NoSignupsText = styled.div`
  font-size: 18px;
  color: #34495e;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
`;
