import { LinkedinIcon, FacebookIcon, TwitterIcon } from "assets/icons";

export enum SocialIcons {
  Twitter = "twitter",
  Facebook = "facebook",
  LinkedIn = "linkedIn",
}

export const socialIcons = {
  [SocialIcons.Twitter]: <TwitterIcon />,
  [SocialIcons.Facebook]: <FacebookIcon />,
  [SocialIcons.LinkedIn]: <LinkedinIcon />,
};
