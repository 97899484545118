import styled from "styled-components";

export const WorkFiguresDiv = styled.div`
  position: absolute;
  top: 3px;
  right: calc(50% - 717px);
  z-index: 2;
  width: 1290px;
  font-size: 0;

  @media only screen and (max-width: 1439px) {
    right: calc(50% - 700px);
  }

  @media only screen and (max-width: 1279px) {
    right: calc(50% - 580px);
  }

  @media (max-width: 767px) {
    top: auto;
    right: 50%;
    bottom: -30px;
    width: 650px;
    -webkit-transform: translateX(30%);
    -ms-transform: translateX(30%);
    transform: translateX(30%);
  }
`;

export default WorkFiguresDiv;
