import { FC, Fragment } from "react";
import * as Styled from "./styles";

interface Props {
  referralBonus: string;
}
export const ReferralPolicy: FC<Props> = ({ referralBonus }) => {
  return (
    <Fragment>
      <Styled.ReferralTermsAndConditions>Terms and Conditions</Styled.ReferralTermsAndConditions>
      <ol>
        <li>
          <Styled.ReferralText>
            Subject to the terms of this agreement, the company will pay the referring person{" "}
            {referralBonus}/- per candidate that gets hired at Remotebase and clears their 3-month
            probation period. The payment schedule is as follows:
          </Styled.ReferralText>
          <ul>
            <li>
              <small>
                <Styled.ReferralText>
                  100% amount will be paid upfront for the very first candidate that gets hired.
                </Styled.ReferralText>
              </small>
            </li>
            <li>
              <small>
                <Styled.ReferralText>
                  50% paid upfront on the day of joining of each subsequent candidate
                </Styled.ReferralText>
              </small>
            </li>
            <li>
              <small>
                <Styled.ReferralText>
                  50% amount will be paid after the completion of the candidate’s 3-month probation
                  period.
                </Styled.ReferralText>
              </small>
            </li>
          </ul>
        </li>
        <li>
          <Styled.ReferralText>
            The {referralBonus}/- referral fee and payment schedule can change based on special
            time-bound campaigns being run by the company. In such a scenario, the new referral fee
            and payment schedule communicated by the company in the campaign brief will supersede
            the above-mentioned payment amount and schedule.
          </Styled.ReferralText>
        </li>
        <li>
          <Styled.ReferralText>
            The candidate's experience should be at least 2 years to qualify for the {referralBonus}
            /- referral bonus.
          </Styled.ReferralText>
        </li>
        <li>
          <Styled.ReferralText>
            The hired candidates having less than 2 years of experience will qualify for the $300/-
            referral bonus.
          </Styled.ReferralText>
        </li>
      </ol>
    </Fragment>
  );
};

export default ReferralPolicy;
