import { FC, Fragment, useMemo } from "react";

interface UsernameProps {
  showFirstName?: boolean;
  capitalized?: boolean;
  fullName?: string;
}

export const Username: FC<UsernameProps> = ({ showFirstName, capitalized, fullName }) => {
  const capitalizeFirstLetter = (val: string): string => {
    const word = val.trim();
    if (!word) return "";
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  const titleCase = (val: string): string =>
    val
      .trim()
      .split(/\s+/g)
      .map((word) => capitalizeFirstLetter(word))
      .join(" ");

  const userName = useMemo(() => {
    let name = titleCase(fullName || "");
    if (showFirstName) [name] = name.split(" ");
    if (capitalized) name = name.toUpperCase();
    return name;
  }, [fullName]);

  return <Fragment>{userName}</Fragment>;
};

export default Username;
