import { createGlobalStyle } from "styled-components";

// REM_BASE px == 1 rem
export const REM_BASE = 16;
// Pixels to rem
export const p2r = (px: number): number => px / REM_BASE;

const GlobalStyle = createGlobalStyle`
  :root {
    --blue: #09aeff;
    --blue-darkened: #11142d;
    --indigo: #2f4bde;
    --indigo-darkened: #1c2d80;
    --indigo-darkened-0: rgba(28, 45, 128, 0);
    --purple: #1030de;
    --purple-darkened: #1e37bc;
    --pink: #f00065;
    --red: #ff2c00;
    --red-darkened: #cc2300;
    --orange: #ff8500;
    --yellow: #ffb600;
    --green: #00e455;
    --green-darkened: #00b142;
    --teal: #20c997;
    --teal-darkened-10: #199d76;
    --teal-darkened-20: #127155;
    --color-danger: #DD3F5B;
    --black: #000;
    --black-80: rgba(0, 0, 0, 0.8);
    --black-70: rgba(0, 0, 0, 0.7);
    --black-60: rgba(0, 0, 0, 0.6);
    --black-50: rgba(0, 0, 0, 0.5);
    --black-40: rgba(0, 0, 0, 0.4);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-25: rgba(0, 0, 0, 0.25);
    --black-20: rgba(0, 0, 0, 0.2);
    --black-18: rgba(0, 0, 0, 0.18);
    --black-15: rgba(0, 0, 0, 0.15);
    --black-10: rgba(0, 0, 0, 0.1);
    --black-10-as-hex: #d9d9d9;
    --black-08: rgba(0, 0, 0, 0.08);
    --black-07: rgba(0, 0, 0, 0.07);
    --black-05: rgba(0, 0, 0, 0.05);
    --black-02: rgba(0, 0, 0, 0.02);
    --black-00: rgba(0, 0, 0, 0);
    --white: #fff;
    --white-99: rgba(255, 255, 255, 0.99);
    --white-96: rgba(255, 255, 255, 0.96);
    --white-87: rgba(255, 255, 255, 0.87);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-69: rgba(255, 255, 255, 0.69);
    --white-65: rgba(255, 255, 255, 0.65);
    --white-60: rgba(255, 255, 255, 0.6);
    --white-55: rgba(255, 255, 255, 0.55);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-25: rgba(255, 255, 255, 0.25);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-10: rgba(255, 255, 255, 0.1);
    --white-00: rgba(255, 255, 255, 0);
    --grey-lightest: #dee2e6;
    --grey-lighter: #c9ccce;
    --grey-light-1: #a5b3c7;
    --grey-light-2: #bfc5ca;
    --grey-light-3: #F2F2F2;
    --grey: #868e96;
    --grey-dark-0: #7f7f7f;
    --grey-dark-1: #757575;
    --grey-dark-2: #555;
    --grey-dark-3: #4a4a4a;
    --grey-dark-4: #4a5568;
    --grey-darker-1: #323d47;
    --grey-darker-2: #343a40;
    --grey-darkest: #191919;
    --blue-grey: #6c757d;
    --blue-grey-lighter: #95aac9;
    --blue-grey-ultra-light: #f0f2f9;
    --blue-grey-darker: #12263f;
    --off-white-1: #edf2f9;
    --off-white-2: #e9f0fe;
    --off-white-3: #f9fbfd;
    --off-white-5: #fafbfb;
    --off-white-6: #eee;
    --off-white-7: #f1f1f1;
    --off-white-8: #e3ebf6;
    --off-white-1-lighten-3: #e2eaf5;
    --off-white-1-darken-5: #dae4f3;
    --off-white-1-darken-10: #c7d6ec;
    --grey-darkest-20: rgba(25, 25, 25, 0.2);
    --grey-darkest-65: rgba(25, 25, 25, 0.65);
    --indigo-25: rgba(47, 75, 222, 0.25);
    --grey-60: rgba(134, 142, 150, 0.6);
    --grey-dark-1-65: rgba(117, 117, 117, 0.65);
    --blue-grey-darker-10: rgba(18, 38, 63, 0.1);
    --blue-grey-ultra-light-30: rgba(240, 242, 249, 0.3);
    --blue-grey-darker-light-shadow: rgba(18, 38, 63, 0.03);
    --grey-darkest-lighten-10: #333;
    --purple-lighten-35: #a8b4f9;
    --yellow-lighten-35: #ffd366;
    --red-lighten-5: #ff411a;

    /* remotebase colors */
    --rb-primary-purple: #6c5dd3;

    /* typography */
    --font-family-main: "Poppins", sans-serif;
    --font-family-base: Open Sans, sans-serif;
    --font-family-base2: Dosis, sans-serif;
    --font-family-base3: Roboto, "Open Sans", sans-serif;
    --font-family-signature: Pacifico, Segoe Script, cursive;

    /* base: 15px */
    --font-size-base: 0.9375rem;
    --font-size-6: 0.375rem;
    --font-size-7: 0.43125rem;
    --font-size-8: 0.496875rem;
    --font-size-9: 0.5625rem;
    --font-size-10: 0.624375rem;
    --font-size-11: 0.684375rem;
    --font-size-12: 0.75rem;
    --font-size-13: 0.80625rem;
    --font-size-14: 0.871875rem;
    --font-size-16: 0.99375rem;
    --font-size-18: 1.125rem;
    --font-size-20: 1.246875rem;
    --font-size-22: 1.36875rem;
    --font-size-24: 1.5rem;
    --font-size-26: 1.621875rem;
    --font-size-28: 1.74375rem;
    --font-size-30: 1.875rem;
    --font-size-32: 1.996875rem;
    --font-size-36: 2.25rem;
    --font-size-38: 2.371875rem;
    --font-size-48: 2.8125rem;
    --font-size-50: 3.121875rem;
    --font-size-60: 3.75rem;
    --font-size-64: 4rem;

    /* spacing */
    --spacing-base: 0.9375rem;
    --spacing-4: 0.25rem;
    --spacing-8: 0.5rem;
    --spacing-10: 0.625rem;
    --spacing-12: 0.75rem;
    --spacing-16: 1rem;
    --spacing-20: 1.25rem;
    --spacing-24: 1.5rem;
    --spacing-26: 1.625rem;
    --spacing-30: 1.875rem;
    --spacing-32: 2rem;
    --spacing-48: 3rem;
    --spacing-50: 3.125rem;
  }
`;

export default GlobalStyle;
