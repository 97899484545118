import styled from "styled-components";

export const LinkSocialsText = styled.div`
  font-size: 18px;
  color: #808191;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
`;

export const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > * {
    margin: 0 10px;
  }
`;

export const IconSingle = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;

// SOCIAL LINKS
export const LinkSocialsBox = styled.div`
  margin-bottom: 84px;
`;
