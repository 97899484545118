import React from "react";
import { Col } from "react-bootstrap";
import { ReferralStages } from "@remotebase/constants";
import { TrackerBoxWithTooltip } from "@remotebase/components";

export type Props = {
  color: string;
  number: number;
  text: ReferralStages;
  dataTestId: string;
  tooltip: string;
  onClick: (e: ReferralStages) => void;
  selectedFilter?: string;
  setSelectedFilter?: (filter: string) => void;
};

export const SingleTrackerBox: React.FC<Props> = ({ dataTestId, ...props }) => {
  return (
    <Col data-testid={dataTestId} xs={12} sm={6} xl={3}>
      <TrackerBoxWithTooltip {...props} />
    </Col>
  );
};

export default SingleTrackerBox;
