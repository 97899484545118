import { FC, Fragment } from "react";
import { refNoSignupsLabel, imageUrl, refEmptyTableTestId } from "utils";
import * as Styled from "./styles";

const EmptyTrackerTable: FC = () => {
  return (
    <Fragment>
      <Styled.NoSignupsImgBox data-testid={refEmptyTableTestId}>
        <img src={imageUrl.noSignup} alt="" />
      </Styled.NoSignupsImgBox>
      <Styled.NoSignupsText>{refNoSignupsLabel}</Styled.NoSignupsText>
    </Fragment>
  );
};

export default EmptyTrackerTable;
