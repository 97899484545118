import { FC } from "react";
import { refDefaultStatus } from "@remotebase/constants";
import { getUpdatedAt } from "../../helpers";

interface Props {
  email?: string | null;
  status?: string | null;
  phoneNo?: string | null;
  updatedAt?: string | null;
  canReinvite?: boolean;
  reInviteBtn(email?: string | null, canReinvite?: boolean): JSX.Element;
}

export const TrackerTableEntry: FC<Props> = ({
  email,
  status,
  updatedAt,
  phoneNo,
  canReinvite,
  reInviteBtn,
}) => {
  return (
    <tr>
      <td>{email}</td>
      <td>{status || refDefaultStatus}</td>
      <td>{phoneNo}</td>
      <td>{updatedAt && getUpdatedAt(updatedAt)}</td>
      <td>{reInviteBtn(email, canReinvite)}</td>
    </tr>
  );
};

export default TrackerTableEntry;
