import styled from "styled-components";

// EMAIL SECTION
export const EmailListBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`;

export const EmailListText = styled.div`
  font-size: 18px;
  color: #808191;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 16px;
`;

export const RefTextAreaBox = styled.div`
  max-width: 550px;
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
  }
`;

export const RefTextArea = styled.textarea`
  font-size: 16px !important;
  color: #808191 !important;
  font-weight: normal !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  padding: 20px !important;
  height: 112px !important;
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 22px !important;
  resize: none !important;
`;

export const PurpleBtn = styled.button`
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  min-height: 52px;
  min-width: 170px;
  padding: 8px 20px;
  position: relative;
  background: #6c5dd3;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 10px 100px rgba(255, 255, 255, 0.1) inset;
  }
  &:active {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.1) inset;
  }
`;

export const RefEmailError = styled.p`
  margin-top: -30px;
  text-align: right;
  font-size: 14px;
  color: red;
  font-style: italic;
`;
