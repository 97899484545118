import { Row } from "react-bootstrap";
import styled from "styled-components";

export const REFERRAL_BOX_ROW = styled(Row)`
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 30px;
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }

  & .col-12 {
    width: auto;
    flex: initial;
    max-width: initial;
    padding-left: 10px;
    padding-right: 10px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 60px;
      border-bottom: 3px dashed #e2e2e2;
      height: 3px;

      @media screen and (max-width: 767px) {
        left: 42px;
        height: 100%;
        border-left: 3px dashed #e2e2e2;
        border-bottom: 0;
      }
    }

    &:nth-last-child(-n + 2) {
      @media screen and (max-width: 767px) {
        &:after {
          height: 50px;
        }
      }
    }

    &:nth-last-child(2) {
      @media screen and (max-width: 575px) {
        &:after {
          height: 100%;
        }
      }
    }

    &:nth-child(4n + 1) {
      @media screen and (min-width: 768px) {
        &:after {
          left: 20px;
        }
      }
    }

    &:last-child,
    &:nth-child(4n + 0) {
      @media screen and (min-width: 768px) {
        &:after {
          width: 50px;
          left: 5px;
        }
      }
    }
  }
`;

export default REFERRAL_BOX_ROW;
