import { upperFirst } from "lodash";
import { FC, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { invalidNumber, phoneNumberPlaceholder, phoneLabel } from "@remotebase/constants";
import { CountryCode, E164Number } from "libphonenumber-js";
import { Country } from "@remotebase/amplify-constants/API";
import { ShouldRender } from "..";
import * as Styled from "./styles";

interface Props {
  setPhoneNumber: (value: string) => void;
  setCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  phoneNumber: string | undefined;
}

export const PhoneNumberInput: FC<Props> = ({ setPhoneNumber, phoneNumber, setCountry }) => {
  const [localPhoneNo, setLocalPhoneNo] = useState("");

  useEffect(() => {
    if (!localPhoneNo && phoneNumber) setLocalPhoneNo(phoneNumber);
  }, [phoneNumber]);

  const handleOnChange = (phoneNo: E164Number): void => {
    if (phoneNo && isValidPhoneNumber(phoneNo as string)) setPhoneNumber(phoneNo as string);
    else setPhoneNumber("");
  };
  return (
    <div className="col-md-5">
      <Styled.CustomLabel>{phoneLabel}</Styled.CustomLabel>
      <Styled.PhoneInputCanvas>
        <PhoneInput
          onCountryChange={(country: CountryCode): void => setCountry(country as Country)}
          placeholder={phoneNumberPlaceholder}
          value={localPhoneNo}
          onChange={handleOnChange}
        />
      </Styled.PhoneInputCanvas>
      <ShouldRender if={phoneNumber === ""}>
        <Styled.CustomError>{upperFirst(invalidNumber)}</Styled.CustomError>
      </ShouldRender>
    </div>
  );
};

export default PhoneNumberInput;
